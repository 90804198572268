
//**************************************************************
// history block animation
//**************************************************************
const nodes = [].slice.call(document.querySelectorAll('.history-block li'), 0);
const directions  = { 0: 'top', 1: 'right', 2: 'bottom', 3: 'left' };
const classNames = ['in', 'out'].map((p) => Object.values(directions).map((d) => `${p}-${d}`)).reduce((a, b) => a.concat(b));

const getDirectionKey = (ev, node) => {
  const { width, height, top, left } = node.getBoundingClientRect();
  const l = ev.pageX - (left + window.pageXOffset);
  const t = ev.pageY - (top + window.pageYOffset);
  const x = (l - (width/2) * (width > height ? (height/width) : 1));
  const y = (t - (height/2) * (height > width ? (width/height) : 1));
  return Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;
}

class Item {
  constructor(element) {
    this.element = element;
    this.element.addEventListener('mouseover', (ev) => this.update(ev, 'in'));  // mouse hover in
    this.element.addEventListener('mouseout', (ev) => this.update(ev, 'out'));  // mouse hover out
  }

  update(ev, prefix) {
    this.element.classList.remove(...classNames);
    this.element.classList.add(`${prefix}-${directions[getDirectionKey(ev, this.element)]}`);
  }
}

nodes.forEach(node => new Item(node));


//**************************************************************
// scroll fadein animation
//**************************************************************
// $(function(){
//  $(window).scroll(function () {
//  $('.fadein').each(function(){
//    var elemPos = $(this).offset().top;
//    var scroll = $(window).scrollTop();
//    var windowHeight = $(window).height();
//    if (scroll > elemPos - windowHeight + 200){
//     $(this).addClass('scrollin');
//    }
//   });
//  });
// });


$(window).scroll(function () {
  $('.fadein').each(function() {
    var elemPos = $(this).offset().top,
      scroll = $(window).scrollTop(),
      windowHeight = $(window).height();

    if (scroll > elemPos - windowHeight + 100) {
      $(this).addClass('scrollin');
    }
  });
});
