// Bootstrapのスタイルシート側の機能を読み込む
// import "bootstrap/dist/css/bootstrap.min.css";
// BootstrapのJavaScript側の機能を読み込む
import "bootstrap";

// import '@fortawesome/fontawesome-free/js/fontawesome';
// import '@fortawesome/fontawesome-free/js/solid';
// import '@fortawesome/fontawesome-free/js/regular';

import "./sass/style.scss";

// import "./js/smooth-scroll.js";
import "./js/common.js";
import "./js/animation.js";
