

//**************************************************************
// 1. fade in animation
//**************************************************************
$(function(){
  function animation(){
    $('.fadeInUp').each(function(){
      //ターゲットの位置を取得
      var target = $(this).offset().top;
      //スクロール量を取得
      var scroll = $(window).scrollTop();
      //ウィンドウの高さを取得
      var windowHeight = $(window).height();
      //ターゲットまでスクロールするとフェードインする
      if (scroll > target - windowHeight){
        $(this).css('opacity','1');
        $(this).css('transform','translateY(0)');
      }
    });
  }
  animation();
  $(window).scroll(function (){
    animation();
  });
});


//**************************************************************
// 2. Scroll ※topを離れたらclass付与
//**************************************************************
function init() {
  // スクロールして何ピクセルでアニメーションさせるか
  var px_change   = 300;

  // スクロールのイベントハンドラを登録
  window.addEventListener('scroll', function(e){
    // 変化するポイントまでスクロールしたらクラスを追加
    if ( $(window).scrollTop() > px_change ) {
      $('header').addClass('smaller');

    // 変化するポイント以前であればクラスを削除
    } else if ( $('header').hasClass('smaller') ) {
      $('header').removeClass('smaller');
      $('#header-nav').removeAttr('checked').prop('checked', false).change();  // topでpull down用のcheckを外す
    }
  });
}
window.onload = init();


//**************************************************************
// 2. Smooth Scroll
//**************************************************************
// var scroll = new SmoothScroll('a[href*="#"]', {
//   header: '#page-top',  // 固定ヘッダー用
//   speed: 500,  // scrool speed
// });


//**********************************************************************
// 3. Parallax scroll
//**********************************************************************
$(function() {
    $(window).scroll(function(){
        var scroll = $(this).scrollTop(); // スクロール値を取得
        // console.info(scroll);
        // $('.scroll_bg01').css('top', parseInt( -scroll / 20 + 10) + '%'); // 1/5のスピード
        // $('.scroll_bg02').css('top', parseInt( -scroll / 25 + 35) + '%'); // 1/5のスピード
        // $('.scroll_bg03').css('top', parseInt( -scroll / 30 + 80) + '%'); // 1/5のスピード
        // $('.scroll_bg01').css('background-position', '5px ' + parseInt( -scroll / 10 ) + 'px'); // 1/5のスピード
        $('.scroll_bg01').css(
          'background-position', '0% ' + parseInt( -scroll / 5 + 100) + 'px,' + '100% ' + parseInt( -scroll / 5 + 1000) + 'px,' + '10% ' + parseInt( -scroll / 8 + 2000) + 'px, ' + '105% ' + parseInt( -scroll / 8 + 3500) + 'px'
        ); // 1/xのスピード
    });
});
